
























































@import '@design';

.item-attr.interactive:not(.static--forced) {
  cursor: pointer;
}

.item-attr {
  user-select: none;
  &:hover:not(.unselected.static) {
    opacity: 0.5;
  }
}

.item-attr--title {
  $dim: 25px;

  width: $dim;
  height: $dim;
  font-size: 9px;
  line-height: $dim - 2px;
  color: $color-db-purple;
  text-align: center;
  border: 2px solid $color-db-purple;
  border-radius: 100%;
}

.placeholder--item-attr,
.unselected {
  cursor: pointer;
  opacity: 0.8;
  .item-attr--title {
    color: grey;
    border-color: grey;
    border-style: dotted;
  }
  &:hover {
    opacity: 1;
    .item-attr--title {
      border-style: solid;
    }
  }
}
