





































@import '@design';

.menu-legend {
  font-size: 0.75rem;
  border: 1px solid lighten(black, 80);
  border-radius: 6px;
  .menu-section--items {
    margin: 1em 0;
  }
  .callout-title--icon {
    height: 48px;
    margin: -3px 12px -3px 0;
  }

  @media (min-width: 600px) {
    .menu-legend--attr {
      flex: 1 1 auto;
    }
  }

  @media (min-width: 1200px) {
    .menu-legend--attr {
      max-width: 250px;
    }
  }
}
